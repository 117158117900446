<template>
  <footer class="footer mt-auto bg-light">
    <div class="container text-center">
      <span class="text-muted">Knotzone | 2024</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'marine-footer',
}
</script>

<style>
/* Additional styling if needed */
</style>
