<template>
  <div class="row feature">
    <!-- Yacht list start -->
    <div class="col-md-3">
      <filter-form :filterProp="receivedFilter" @send-filter="receiveFilterFromChild" @init-filter="receiveFilterFromChildWithoutBase" ref="yachtFilterComp" />
    </div>
    <div class="col-md-9">
      <yacht-list :countOfPage="countOfPage" :filterProp="receivedFilter" ref="yachtListComp"
                  @send-filter="receiveFilterFromYachtList" />
    </div>
    <!-- Yacht list end -->
  </div>
</template>

<script>
import YachtService from "@/service/YachtService";
import YachtList from "@/components/YachtList.vue";
import FilterForm from "@/components/FilterForm.vue";

export default {
  name: "search-form",
  components: {YachtList, FilterForm},
  data() {
    return {
      countOfPage: {
        type: Number,
        require: true
      },
      receivedFilter: null
    }
  },
  methods: {
    async retrieveYachts() {
      await YachtService.getCountOfPage(this.receivedFilter)
          .then(response => {
            this.countOfPage = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },
    async receiveFilterFromChild(filter) {
      this.receivedFilter = filter;
      await this.retrieveYachts();
      this.$refs.yachtListComp.initWithFilters();
    },
    receiveFilterFromYachtList(filter) {
      this.receivedFilter = filter;
      this.retrieveYachts();
      this.$refs.yachtFilterComp.initWithFilters(filter);
    }
  },
  mounted() {
    this.retrieveYachts();
  }
};
</script>

<style>

</style>
