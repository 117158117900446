<script setup>

</script>

<template>
  <div class="bg-white w-5/6 m-auto rounded-xl shadow-xl shadow-grey-400 mt-20 text">
    <br>
    <h1 class="text-4xl">Privacy Policy</h1>
    <br>
    <h2 class="text-3xl">Introduction</h2>
    <br>
    <p>We are committed to protecting your personal data and ensuring transparency in how we collect, use, and safeguard your information. As a non-commercial platform, we aim to provide a safe and respectful environment for all users. This Privacy Policy explains what data we collect, how we use it, and your rights concerning your data.</p>
    <br>
    <h2 class="text-3xl">Data Collection</h2>
    <br>
    <p>We collect personal information in the following ways:</p>
    <ul>
      <li><strong>Information You Provide:</strong> We may collect your name, email address, and other contact details when you voluntarily provide them through forms on our website.</li>
      <li><strong>Automated Data Collection:</strong> We use cookies and other tracking technologies to collect information about your interactions with our website, such as your IP address, browser type, and browsing behavior.</li>
    </ul>
    <br>
    <h2 class="text-3xl">Use of Collected Data</h2>
    <br>
    <p>We use the data we collect for the following purposes:</p>
    <ul>
      <li><strong>To Improve User Experience:</strong> The data helps us understand how users interact with our site and allows us to make improvements based on that information.</li>
      <li><strong>Communication:</strong> If you provide your contact details, we may use them to communicate with you regarding your experience on our platform or to send you updates about our activities.</li>
      <li><strong>Analytics and Performance:</strong> We use data analytics to monitor the performance of our site and ensure it is functioning optimally.</li>
    </ul>
    <br>
    <h2 class="text-3xl">Cookies</h2>
    <br>
    <p>Cookies are small text files stored on your device that help us enhance your experience on our site. We use the following types of cookies:</p>
    <ul>
      <li><strong>Essential Cookies:</strong> These cookies are necessary for the proper functioning of our website.</li>
      <li><strong>Analytics Cookies:</strong> We use these cookies to gather insights into how users interact with our site, helping us improve its functionality and performance.</li>
    </ul>
    <p>You can control or delete cookies through your browser settings. However, please note that disabling cookies may impact your experience on our website.</p>
    <br>
    <h2 class="text-3xl">Data Sharing</h2>
    <br>
    <p>We do not share your personal data with third parties except in the following situations:</p>
    <ul>
      <li><strong>With Your Consent:</strong> We may share information when you have provided explicit consent.</li>
      <li><strong>Legal Requirements:</strong> If required by law, we may disclose your data to comply with legal obligations.</li>
    </ul>
    <br>
    <h2 class="text-3xl">Third-Party Services</h2>
    <br>
    <p>We may use third-party services such as Google Analytics to help us analyze website traffic and improve our services. These third parties may collect data about your online activities across different websites over time.</p>
    <br>
    <h2 class="text-3xl">Your Rights</h2>
    <br>
    <p>You have the following rights regarding your personal data:</p>
    <ul>
      <li><strong>Access:</strong> You can request a copy of the personal data we hold about you.</li>
      <li><strong>Correction:</strong> You have the right to request that we correct any inaccuracies in your data.</li>
      <li><strong>Deletion:</strong> You can request that we delete your personal data under certain circumstances.</li>
      <li><strong>Consent Withdrawal:</strong> If you have provided consent for data processing, you can withdraw it at any time.</li>
    </ul>
    <p>To exercise these rights, please contact us at <a href="mailto:admin@knotzone.com">admin@knotzone.com</a>.</p>
    <br>
    <h2 class="text-3xl">Changes to This Policy</h2>
    <br>
    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review it periodically.</p>
    <br>
    <h2 class="text-3xl">Contact Us</h2>
    <br>
    <p>If you have any questions about this Privacy Policy or how we handle your data, please contact us at:</p>
    <p>
      KnotZone.com<br>
      <a href="mailto:admin@knotzone.com">admin@knotzone.com</a><br>
    </p>
  </div>
</template>

<style scoped>
  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    padding: 0;
    color: #333;
  }
  h1, h2 {
    color: #0056b3;
  }
  a {
    color: #0056b3;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
</style>