import {createApp} from 'vue'
import App from './App.vue'
import '@/assets/css/global.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/js/bootstrap.js'
import router from './router'
import store from "./store";
import components from '@/components/UI'
import BootstrapVue3 from 'bootstrap-vue-3'
import './interceptors/axios'
import { createMetaManager  } from 'vue-meta'
import { createHead } from '@vueuse/head';


const app = createApp(App)

Object.values(components).forEach(component => {
    app.component(component.name, component);
})

app.use(BootstrapVue3);
app.use(router);
app.use(store);

app.use(createHead());
app.use(createMetaManager());

app.mount('#app')
