<template>
  <main class="form-signin w-100 m-auto">
    <div v-if="notify.cls" :class="`alert alert-${notify.cls}`" role="alert">
      {{ notify.message }}
    </div>
    <form @submit.prevent="submit">
      <img class="mb-4" src="../../assets/img/logo/our-logo.png" alt="our logo" width="290" height="200">
      <h1 class="h3 mb-3 fw-normal">Please insert your email</h1>

      <div class="form-floating">
        <input v-model="email" type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
        <label for="floatingInput">Email address</label>
      </div>

      <button class="mt-3 btn btn-primary w-100 py-2" type="submit">Submit</button>
    </form>
  </main>
</template>

<script>
import {defineComponent, reactive, ref} from 'vue'
import axios from "axios";

export default defineComponent({
  name: "ForgotPass",
  setup() {
    const email = ref('');
    const notify = reactive( {
      cls: '',
      message: ''
    })

    const submit = async () => {
      await axios.post('/auth/forgot', {
        email: email.value
      }).then(resp => {
        if (resp.status === 200) {
          notify.cls = 'success';
          notify.message = 'Email was sent!';
        } else {
          notify.cls = 'danger';
          notify.message = 'Email does not exist!';
        }
      });
    }

    return {
      email,
      submit,
      notify
    }
  }
})
</script>

<style scoped>
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>