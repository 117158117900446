<script setup>
import axios from "axios";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import ImageModalComponent from "@/components/ImageModalComponent.vue";
import Dropdown from "@/components/Dropdown.vue";
import ChildYachts from "@/view/ChildYachts.vue";
import {useHead} from "@vueuse/head";

const route = useRoute();

const yacht = ref({});

const isImgModelOpen = ref(false);

const modalIndex = ref(0);

const childYachts = ref([]);

const openImgModel = (index) => {
  modalIndex.value = index;
  isImgModelOpen.value = true;
}

const closeImgModal = () => {
  isImgModelOpen.value = false;
}

const loadCatalogYacht = async () => {
  try {
    const id = route.params.id;
    const response = await axios.get(`https://api.knotzone.com/api/v1/catalog/get/by/${id}`);
    yacht.value = response.data.yacht;
    childYachts.value = response.data.yachts;
  } catch (error) {
    console.error('Error loading groups:', error);
  }
};

onMounted(async () => {
  await loadCatalogYacht();

  useHead({
    title: `KnotZone сatalog - ${yacht.value.name}`,
    meta: [
      {
        name: 'description',
        content: `Look at detailed information about the yacht named ${yacht.value.name} in the KnotZone's catalog.`,
      },
      {
        name: 'og:type',
        content: 'website',
      },
      {
        name: 'og:title',
        content: `KnotZone - сatalog yachts`,
      },
      {
        name: 'og:url',
        content: `https://knotzone.com/catalog/yacht/${yacht.value.id}`,
      },
      {
        name: 'og:image',
        content: getImgOGImage(yacht.value),
      },
      {
        name: 'og:description',
        content: `Look at detailed information about the yacht named ${yacht.value.name} in the KnotZone's catalog.`,
      },
    ],
  });

  function getImgOGImage(yacht) {
    if (yacht.images.length) {
      return yacht.images[0];
    }
    return null;
  }

});

</script>

<template>
  <div class="max-w-4xl mx-auto p-5 bg-white rounded-lg shadow-lg">
    <div class="text-center mb-6">
      <h1 class="text-2xl font-bold">{{ yacht.name }}</h1>
      <p v-if="yacht.designer" class="text-sm text-gray-500">Designed by: {{ yacht.designer }}</p>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div>
        <h2 class="text-lg font-semibold">Specifications</h2>
        <ul class="list-disc pl-5">
          <li v-if="yacht.ballastType">Ballast type: {{ yacht.ballastType }}</li>
          <li v-if="yacht.ballastKg !== 0 && yacht.ballastLb !== 0">Ballast: {{ yacht.ballastKg }} kg
            ({{ yacht.ballastLb }} lb)
          </li>
          <li v-if="yacht.beamFt !== 0 && yacht.beamM !== 0">Beam: {{ yacht.beamFt }} ft ({{ yacht.beamM }} m)</li>
          <li v-if="yacht.displacementKg !== 0 && yacht.displacementLb !== 0">Displacement: {{ yacht.displacementKg }}
            kg ({{ yacht.displacementLb }} lb)
          </li>
          <li v-if="yacht.hullSpeed">Hull Speed: {{ yacht.hullSpeed }} knots</li>
          <li v-if="yacht.laoft !== 0 && yacht.laom !== 0">LOA: {{ yacht.laoft }} ft ({{ yacht.laom }} m)</li>
          <li v-if="yacht.lwlft !== 0 && yacht.lwlm !== 0">LWL: {{ yacht.lwlft }} ft ({{ yacht.lwlm }} m)</li>
          <li v-if="yacht.maxDraftFt !== 0 && yacht.maxDraftM !== 0">Max Draft: {{ yacht.maxDraftFt }} ft
            ({{ yacht.maxDraftM }} m)
          </li>
          <li v-if="yacht.riggingType">Rigging Type: {{ yacht.riggingType }}</li>
          <li v-if="yacht.hullType">Hull Type: {{ yacht.hullType }}</li>
          <li v-if="yacht.comfortRatio != 0">Comfort Ratio: {{ yacht.comfortRatio }}</li>
        </ul>
      </div>
      <div>
        <h2 class="text-lg font-semibold">Dimensions</h2>
        <ul class="list-disc pl-5">
          <li v-if="yacht.ift !== 0 && yacht.im !== 0">Fore Triangle: {{ yacht.ift }} ft ({{ yacht.im }} m)</li>
          <li v-if="yacht.forestayLengthFT !== 0 && yacht.forestayLengthM !== 0">>Forestay Length:
            {{ yacht.forestayLengthFT }} ft ({{ yacht.forestayLengthM }} m)
          </li>
          <li v-if="yacht.jft !== 0 && yacht.jm !== 0">>J Measurement: {{ yacht.jft }} ft ({{ yacht.jm }} m)</li>
          <li v-if="yacht.pft !== 0 && yacht.pm !== 0">P Measurement: {{ yacht.pft }} ft ({{ yacht.pm }} m)</li>
          <li v-if="yacht.eft !== 0 && yacht.em !== 0">E Measurement: {{ yacht.eft }} ft ({{ yacht.em }} m)</li>
          <li>First Built: {{ yacht.firstBuilt }}</li>
        </ul>
      </div>
    </div>
    <div class="mt-4">
      <h2 class="text-lg font-semibold">Images</h2>
      <div class="flex space-x-4">
        <img v-for="(image, index) in yacht.images" :key="index" :src="image" alt="Yacht Image"
             class="w-32 h-32 rounded"
             @click="openImgModel(index)"/>
        <ImageModalComponent :images="yacht.images"
                             :is-open="isImgModelOpen"
                             :index="modalIndex"
                             @modal-close="closeImgModal" />
      </div>
    </div>
    <div class="text-center mb-6 mt-6">
      <Dropdown :title="`View options for purchasing this yacht | Number of yachts: ${childYachts.length}`">

        <template #header>
          <div class="flex w-full items-center text-center justify-between border-b border-gray-300 py-2">
            <h1 class="text-2xl font-bold">
              View options for purchasing this yacht | Number of yachts: {{childYachts.length}}
            </h1>
          </div>
        </template>
        <template #button>
          <div class="bg-blue-300 hover:bg-blue-500 text-white p-1 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
          </div>
        </template>
        <template #content>
          <ChildYachts :yachts="childYachts"/>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<style scoped>

</style>