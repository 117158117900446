<template>
  <header class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <router-link to="/" @click="reloadPage" class="navbar-brand">Knotzone</router-link>
      <div class="navbar-collapse">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <input type="text" class="form-control" placeholder="Поиск" style="display: none">
          </li>
        </ul>
        <ul class="navbar-nav flex justify-end" v-if="auth">
          <li class="nav-item">
            <router-link :to="'/catalog'" class="router nav-link">
              Catalog
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/favorite'" class="router nav-link">
              Favorite
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/profile'" class="router nav-link">
              Profile
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/'" @click="logout" class="router nav-link">
              Logout
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav" v-if="!auth">
          <li class="nav-item">
            <router-link :to="'/favorite'" class="router nav-link">
              Favorite
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/register'" class="router nav-link">
              Register
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/login'" class="router nav-link">
              Login
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import {computed} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import router from "@/router";

export default {
  name: 'marine-header',
  setup() {
    const store = useStore();

    const auth = computed(() => store.state.auth);

    const logout = async () => {
      await axios.post("/auth/logout", {}, {withCredentials: true})

      axios.defaults.headers.common['Authorization'] = ``;

      await store.dispatch('setAuth', false);
      await store.dispatch('clearLocalDate');
    }

    const reloadPage = async () => {
      await router.push('/');
      await window.location.reload();
    }

    return {
      auth,
      logout,
      reloadPage
    }
  }
}
</script>
<style scoped>
  .router {
    text-decoration: none !important;
  }
  .router:hover {
    border: 0;
  }
</style>