<template>
  <div>
    <div class="row">
      <div class="col-9" />
      <div class="col-3 mt-3 mb-3 sort-list-width-for-phone">
        <b-form-select id="type" style="color: grey"
                       :options="sortOptions"
                       v-model="sortMethod"
                       @input="changeSortOption">
        </b-form-select>
      </div>
    </div>
    <b-card-group v-if="items.length">
      <ModalComponent :is-open="isFavModalOpen"
                      @modal-close="closeModal"
                      @submit.prevent="clickFavorite(selectedYachtForFavorite)"
                      name="first-modal">
        <template #header>
          <div class="text-center w-full">
            <h3>Add group</h3>
          </div>
        </template>
        <template #content>
          <form @submit.prevent="clickFavorite(selectedYachtForFavorite)">
            <p>This yacht has been added to {{ numberOfFavorite }} groups of favorites, do you really want to remove it
              from favorites?</p>
            <div class="flex space-x-2">
              <button
                  class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                  type="submit">
                Yes
              </button>
              <button
                  class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                  type="button"
                  @click="closeModal">
                Cansel
              </button>
            </div>
          </form>
        </template>
        <template #footer>
        </template>
      </ModalComponent>
      <div class="col-4 mb-2 post-width-for-phone" v-for="(yacht, index) in items" :key="index">
        <b-card tag="article" class="mb-2 select-card single-card z-20">
          <div @click="checkBeforeClickOnFavorite(yacht)" class="absolute top-8 right-8 z-50">
            <img :src="favorite(yacht) ? '/like-2.svg' : '/like-1.svg'" alt="Favorite" />
          </div>
          <router-link :to="'/yachts/' + yacht.id" class="router">
            <template v-if="yacht.image">
              <b-card-img :src="yacht.image" :alt="yacht.name" class="img-class" />
            </template>
            <template v-else>
              <b-card-img :src="getEmptyImg" :alt="yacht.name" class="img-class" />
            </template>
            <b-card-title v-if="yacht.id === 36796">
              <div class="font-bold">
                Nova 27 | {{ yacht.nominalLengthM }}m | {{
                  getCondition(yacht.isNew)
                }}
              </div>
            </b-card-title>
            <b-card-title v-if="yacht.id !== 36796">
              {{ yacht.name }} | {{ yacht.nominalLengthM }}m | {{
                getCondition(yacht.isNew)
              }}
            </b-card-title>
            <b-card-text v-if="yacht.id === 36796">
              <div class="row">
                <div class="line-through font-bold">12.000 EUR</div>
                <div class="font-bold" style="color: rgba(43, 94, 25)">{{ formatPrice(yacht.price) }} {{
                    yacht.currency
                  }} {{ formatPriceEur(yacht.priceEUR, yacht) }}
                </div>
              </div>
            </b-card-text>
            <b-card-text v-if="yacht.id !== 36796">
              {{ formatPrice(yacht.price) }} {{ yacht.currency }} {{ formatPriceEur(yacht.priceEUR, yacht) }}
            </b-card-text>
            <b-card-footer>
              <div class="text-to-right">
                <small v-if="yacht.createdDate != null" class="text-muted">
                  Create date: {{ getDatePattern(yacht.createdDate) }}
                </small>
                <br>
                <div v-if="yacht.id !== 36796">
                  <small v-if="yacht.modifiedDate != null" class="text-muted">
                    Modified date: {{ getDatePattern(yacht.modifiedDate) }}
                  </small>
                  <br>
                  <small v-if="yacht.lastUpdateDate != null" class="text-muted">
                    Last check: {{ getTimeElapsed(yacht.lastUpdateDate) }}
                  </small>
                </div>
                <div v-if="yacht.id === 36796" style="color: rgba(43, 94, 25) !important;">
                  <b >
                    The announcement is relevant!
                  </b>
                  <br>
                  <b>
                    A trusted seller!
                  </b>
                </div>
              </div>
            </b-card-footer>

          </router-link>
        </b-card>
      </div>

      <b-pagination
          v-if="countOfPage > 1"
          v-model="currentPage"
          :total-rows="countOfPage"
          :per-page="1"
          align="center"
          @click="changePage"
          first-number
          last-number
      ></b-pagination>
    </b-card-group>
    <b-card-group v-if="isEmpty" style="text-align: center">
      <h1 class="text-3xl font-bold">Yachts matching the current filters were not found</h1>
    </b-card-group>
    <b-card-group v-if="isEmpty" style="text-align: center">
      <div class="col-3">
        <img src="@/assets/img/background-yacht.png" alt="Image" style="height: 500px; opacity: 0.2;max-width: 400px;"
             class="centered-image">
      </div>
    </b-card-group>
  </div>
</template>

<script>
import YachtService from "@/service/YachtService";
import {mapGetters, mapMutations} from "vuex";
import {computed} from "vue";
import store from "@/store";
import axios from "axios";
import ModalComponent from "@/components/UI/ModalComponent.vue";
import yacht from "@/components/Yacht.vue";


export default {
  name: "yacht-list",
  components: {ModalComponent},
  props: {
    countOfPage: Number,
    filterProp: null
  },
  // Ascending - возрастание
  // Descending - убывание
  data() {
    return {
      isEmpty: false,
      currentPage: 1, // Текущая страница
      filter: null,
      items: [],
      sortMethod: 'recentlyUpdatedDescending',
      sortOptions: [
        {value: 'recentlyUpdatedDescending', text: 'Recently Updated: Newest first'},
        {value: 'recentlyUpdatedAscending', text: 'Recently Updated: Oldest first'},
        {value: 'yearDescending', text: 'Year: Newest first'},
        {value: 'yearAscending', text: 'Year: Oldest first'},
        {value: 'lengthDescending', text: 'Length: Longest first'},
        {value: 'lengthAscending', text: 'Length: Shortest first'},
        {value: 'priceDescending', text: 'Price: High to Low'},
        {value: 'priceAscending', text: 'Price: Low to High'},
        {value: 'makeAToZ', text: 'Make: (A to Z)'},
        {value: 'makeZToA', text: 'Make: (Z to A)'}
      ],
      isFavModalOpen: false,
      numberOfFavorite: 0,
      selectedYachtForFavorite: null
    };
  },
  methods: {
    getImage(imgUrl) {
      // Create Data URI
      return require(imgUrl);
    },
    // set date format
    getDatePattern(date) {
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
      const day = String(selectedDate.getDate()).padStart(2, '0');
      return `${year}.${month}.${day}`;
    },
    // set price format
    formatPrice(value) {
      if (value == null) return 'price is not specified';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPriceEur(value, currentYacht) {
      if (value == null) return '';
      if (currentYacht.currency === 'EUR') return '';
      return '( ≈ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' EUR)';
    },
    // calculate update time
    getTimeElapsed(date) {
      const currentTime = new Date();
      const timeDiffInSeconds = Math.floor((currentTime - new Date(date)) / 1000);

      if (timeDiffInSeconds < 60) {
        return 'Less than a minute ago';
      } else if (timeDiffInSeconds < 3600) {
        const minutes = Math.floor(timeDiffInSeconds / 60);
        return `${minutes} minutes ago`;
      } else if (timeDiffInSeconds < 3600 * 24) {
        const hours = Math.floor(timeDiffInSeconds / 3600);
        const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
        return `${hours} hours and ${minutes} minutes ago`;
      } else {
        const days = Math.floor(timeDiffInSeconds / (3600 * 24));
        if (days > 1) {
          return `${days} days ago`
        } else {
          return `${days} day ago`
        }
      }
    },
    // set condition
    getCondition(isNew) {
      if (isNew) {
        return 'NEW';
      } else {
        return 'USED';
      }
    },
    isNullOrEmpty(value) {
      return value === null || value === '';
    },
    // methode for change page's number
    getFilterRout() {
      if (this.filter === null) {
        return '';
      }

      let result = '';
      for (const key in this.filter) {
        if (!this.isNullOrEmpty(this.filter[key])) {
          if (key === 'condition' && this.filter[key] === false) {
            continue;
          }
          result += `${key}=${this.filter[key].toString()}&`;
        }
      }

      // Убираем последний символ "&" из результата, если он есть
      if (result.endsWith('&')) {
        result = result.slice(0, -1);
      }

      return result;
    },
    changePage() {
      if (this.currentPage === 1) {
        if (this.sortMethod === 'recentlyUpdatedDescending') {
          if (!this.isNullOrEmpty(this.getFilterRout())) {
            this.$router.push(`/yachts/filters/${this.getFilterRout()}`);
          } else {
            this.$router.push(`/`);
          }
        } else if (this.filter === null) {
          this.$router.push(`/yachts/sort-by/${this.sortMethod}`);
        } else {
          this.$router.push(`/yachts/sort-by/${this.sortMethod}/filters/${this.getFilterRout()}`);
        }
      } else {
        if (this.filter === null) {
          this.$router.push(`/yachts/sort-by/${this.sortMethod}/page/${this.currentPage}`);
        } else {
          this.$router.push(`/yachts/sort-by/${this.sortMethod}/filters/${this.getFilterRout()}/page/${this.currentPage}`);
        }
      }
      this.getYachtsForPage();
    },
    changeSortOption() {
      this.currentPage = 1;
      this.$router.push(`/yachts/sort-by/${this.sortMethod}`);
      this.getYachtsForPage();
    },
    setCharacteristicForPage() {
      const sort = this.$route.params.sort;
      this.sortMethod = typeof sort === 'undefined' ? 'recentlyUpdatedDescending' : sort;
      const requestFilter = this.$route.params.filters;
      if (!(requestFilter === 'undefined')) {
        this.initFilterByRoutParam(requestFilter);
        this.sendFilterToParent();
      }
      const page = parseInt(this.$route.params.page);
      this.currentPage = isNaN(page) ? 1 : page;
    },
    initFilterByRoutParam(filterString) {
      const params = new URLSearchParams(filterString);
      if (this.filter === null && filterString != null) this.filter = {};
      params.forEach((value, key) => {
        this.filter[key] = value;
      });
    },
    getYachtsForPage() {
      YachtService.getAllByPageNumber(this.sortMethod, this.filter, this.currentPage)
          .then(response => {
            this.items = response.data;
            this.isEmpty = this.items.length === 0;
          })
          .catch(e => {
            console.log(e);
          });
    },
    sendFilterToParent() {
      this.$emit("send-filter", this.filter);
    },
    initWithFilters() {
      this.filter = this.filterProp;
      this.currentPage = 1;
      this.changePage();
      this.getYachtsForPage();
    },
    ...mapMutations(["editFavorite"]),
    clickFavorite(yacht) {
      this.editFavorite(yacht);
      this.isFavModalOpen = false;
    },
    ...mapGetters(["getFavorite"]),
    favorite(yacht) {
      let isFav = false;
      if (this.getFavorite()) {
        this.getFavorite().forEach(fav => {
          if (fav.yacht != null && fav.yacht.id === yacht.id) {
            isFav = true;
          }
        });
      }
      return isFav;
    },
    async checkBeforeClickOnFavorite(yacht) {
      const getAuth = computed(() => store.getters.getAuth);
      const getUserId = computed(() => store.getters.getUserId);
      if (getAuth.value && this.favorite(yacht)) {
        const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/exist/yacht/${yacht.id}/in/${getUserId.value}`);
        if (response.data.number === 0) {
          this.clickFavorite(yacht);
        } else {
          this.numberOfFavorite = response.data.number;
          this.isFavModalOpen = true;
          this.selectedYachtForFavorite = yacht;
        }
      } else {
        this.clickFavorite(yacht);
      }
    },
    closeModal() {
      this.isFavModalOpen = false;
    }
  },
  mounted() {
    this.setCharacteristicForPage();
    this.getYachtsForPage();
  },
  computed: {
    yacht() {
      return yacht
    },
    getEmptyImg() {
      return require(`@/assets/img/img-missing.jpg`);
    },

  }
};
</script>

<style>

.img-class {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.router {
  text-decoration: none !important;
}

.router:hover {
  border: 2px rgb(0, 0, 0) solid;
}

.select-card:hover {
  border: 1px solid black;
}

.single-card {
  width: 100%;
  height: 100%;
  background: #F8F9FA;
}

.text-to-right {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .post-width-for-phone {
    width: 100%;
  }

  .sort-list-width-for-phone {
    width: 100%;
  }

  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>