<template>
  <main class="form-signin w-100 m-auto">
    <form @submit.prevent="submit">
      <img class="mb-4" src="../../assets/img/logo/our-logo.png" alt="our logo" width="290" height="200">
      <h1 class="h3 mb-3 fw-normal">Please reset your password</h1>

      <div class="form-floating mb-3">
        <input v-model="data.password" type="password" class="form-control" placeholder="Password">
        <label>Password</label>
      </div>
      <div class="form-floating mb-3">
        <input v-model="data.password_confirm" type="password" class="form-control" placeholder="Password Confirm">
        <label>Password Confirm</label>
      </div>
      <button class="btn btn-primary w-100 py-2" type="submit">Submit</button>
    </form>
  </main>
</template>

<script>
import {defineComponent, reactive} from 'vue'
import axios from "axios";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  name: "ResetPass",
  setup() {
    const data = reactive({
      password: '',
      password_confirm: ''
    });

    const route = useRoute();
    const router = useRouter();

    const submit = async () => {
      await axios.post(`/auth/reset/${route.params.token}`, data);

      await router.push('/login');
    }

    return {
      data,
      submit
    }
  }
})
</script>

<style scoped>
  html,
  body {
    height: 100%;
  }

  .form-signin {
    max-width: 330px;
    padding: 1rem;
  }

  .form-signin .form-floating:focus-within {
    z-index: 2;
  }

  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
</style>