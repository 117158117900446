<script setup>
import FavoriteGroup from "@/components/FavoriteGroup.vue";
import ModalComponent from "@/components/UI/ModalComponent.vue";
import Dropdown from "@/components/Dropdown.vue";
import {computed, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import ImageModalComponent from "@/components/ImageModalComponent.vue";

const isModalOpened = ref(false);

const isModalAddToGroupOpened = ref(false);

const store = useStore();

const getFavorite = computed(() => store.getters.getFavorite);

const getAuth = computed(() => store.getters.getAuth);

const getUserId = computed(() => store.getters.getUserId);

const groups = ref([]);

const selected = reactive([]);

const isImgModelOpen = ref(false);

const imgModel = ref([]);

const openModalAddToGroup = () => {
  isModalAddToGroupOpened.value = true;
};
const closeModalAddToGroup = () => {
  isModalAddToGroupOpened.value = false;
};

const submitModalAddToGroup = async (group) => {

  const data = {
    favorites: selected,
    groupId: group.id,
    userId: getUserId.value
  };

  await axios.post('https://api.knotzone.com/api/v1/favorite/group/add/favorites', data)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  await refreshFavoriteData();
  await loadGroups();
  selected.value = [];
  closeModalAddToGroup();
};

const refreshFavoriteData = async () => {
  try {
    const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/by/userId/${getUserId.value}`);
    const dbFav = response.data.favorites;
    await store.dispatch('loadFromDb', dbFav);
  } catch (error) {
    console.error('Error loading groups:', error);
  }
}

const openModal = () => {
  isModalOpened.value = true;
};
const closeModal = () => {
  isModalOpened.value = false;
};

const groupName = ref(null);

const removeFav = async  (item) => {
  await deleteFavorite(item);
  await loadGroups();
  selected.value = [];
}

const deleteFavorite = async (item) => {
  await store.commit('editFavorite', item);
  await loadGroups();
  selected.value = [];
};

const deleteAllSelected = async () => {
  for (let selectedElement of selected) {
    await deleteFavorite(selectedElement);
  }
  await loadGroups();
  selected.value = [];
};

const submitHandler = async () => {

  const data = {
    name: groupName.value,
    userId: getUserId.value
  };

  await axios.post('https://api.knotzone.com/api/v1/favorite/group/save', data)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });

  addGroup(groupName.value);
  groupName.value = null;
  await loadGroups();
  closeModal();
};

const toggleSelection = (item) => {
  const index = selected.findIndex(select => select.yacht.id === item.yacht.id);
  if (index === -1) {
    selected.push(item);
  } else {
    selected.splice(index, 1);
  }
};

const isSelected = (item) => {
  return selected.some(selectedItem => selectedItem.yacht.id === item.yacht.id);
};

const formatPrice = (value) => {
  if (value == null) return 'price is not specified';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const addGroup = (name) => {
  groups.value.push({
    id: null,
    name: name,
    isPublic: null,
    favorites: []
  });
};

const closeImgModal = () => {
  isImgModelOpen.value = false;
};

const openImgModal = (img) => {
  imgModel.value = img
  isImgModelOpen.value = true;
};

const openImgModalWithReqToDb = async (yachtId) => {
  try {
    const response = await axios.get(`https://api.knotzone.com/api/v1/yacht/get/all/images/${yachtId}`);
    imgModel.value = response.data.images;
  } catch (error) {
    console.error('Error loading images:', error);
  }
  isImgModelOpen.value = true;
};

const loadGroups = async () => {
    try {
      const response = await axios.get(`https://api.knotzone.com/api/v1/favorites/user/${getUserId.value}`);
      groups.value = response.data.favoriteGroups;
    } catch (error) {
      console.error('Error loading groups:', error);
    }
};

onMounted(() => {
  loadGroups();
});
</script>

<template>
  <div class="bg-white w-5/6 m-auto rounded-xl shadow-xl shadow-grey-400 mt-20">
    <div class="row mb-2">
      <h1 class="mx-auto text-center text-gray-600">Groups</h1>
    </div>
    <template v-if="getAuth">

      <div v-for="group in groups" :key="group.id">
        <Dropdown :title="`${group.name}`">
          <template #content>
            <FavoriteGroup :favorites="group.favorite" :groupId="group.id" />
          </template>
        </Dropdown>
      </div>
      <div class="text-center mb-3">
        <button class="w-1/5 rounded-full border-2 bg-slate-300 hover:bg-slate-400"
                @click="openModal">
          <h5> Add group </h5>
        </button>
        <ModalComponent :is-open="isModalOpened"
                        @modal-close="closeModal"
                        @submit.prevent="submitHandler"
                        name="first-modal">
          <template #header>
            <div class="text-center w-full">
              <h3>Add group</h3>
            </div>
          </template>
          <template #content>
            <form @submit.prevent="submitHandler">
              <div>
                <label for="group_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Group
                  name:</label>
                <input v-model="groupName" type="text" id="group_name"
                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder="Favorites yachts" required />
              </div>
              <button
                  class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                  type="submit">
                Submit
              </button>
            </form>
          </template>
          <template #footer>
          </template>
        </ModalComponent>
      </div>
    </template>
    <template v-if="!getAuth">
      <div class="text-center">
        <h3>To use groups you need
          <router-link :to="'/login'" class="router text-blue-500">authorization!</router-link>
        </h3>
      </div>
    </template>

    <div class="home">
      <div class="container">
        <div class="row">
          <h1 class="mx-auto text-center text-gray-600">Favorite</h1>
        </div>
      </div>

      <div style="text-align: right" v-if="selected.length">
        <button @click="deleteAllSelected" title="Delete selected">
          <svg class="h-8 w-8 text-blue-400 hover:text-blue-500 hover:h-9 hover:w-9" width="24" height="24"
               viewBox="0 0 24 24" stroke-width="2"
               stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="4" y1="7" x2="20" y2="7" />
            <line x1="10" y1="11" x2="10" y2="17" />
            <line x1="14" y1="11" x2="14" y2="17" />
            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
          </svg>
        </button>
        <button title="Add to group" @click="openModalAddToGroup">
          <svg class="h-8 w-8 text-blue-400 hover:text-blue-500 hover:h-9 hover:w-9" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>
      <div class="text-center">
        <ModalComponent :is-open="isModalAddToGroupOpened"
                        @modal-close="closeModalAddToGroup"
                        @submit="submitHandler"
                        name="first-modal">
          <template #header>
            <div class="text-center w-full">
              <h3>Add to group</h3>
            </div>
          </template>
          <template #content>
            <form>
              <div v-for="group in groups" :key="group.id">
                <button
                    class="w-full rounded border-solid border-2 border-blue-800 bg-blue-300 mb-2 hover:bg-blue-500"
                    @click.prevent="submitModalAddToGroup(group)"
                >
                  {{ group.name }}
                </button>
              </div>
            </form>
          </template>
          <template #footer>
          </template>
        </ModalComponent>
      </div>
      <div class="container">
        <div class="row">
          <p v-if="getFavorite.length === 0" class="col-12 text-center">
            Bookmarks are empty.
            <router-link to="/" class="router text-blue-600 border-b">
              Bookmark something.
            </router-link>
            Group of selected
          </p>
          <div v-else class="table-responsive">
            <table class="table col-12">
              <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Delete</th>
                <th v-if="getAuth" scope="col" />
              </tr>
              </thead>
              <tbody>
              <tr v-for="(favorite) in getFavorite" :key="favorite.yacht.id">
                <td class="align-middle">
                  <img
                      v-if="favorite.yacht.image"
                      :src="`${favorite.yacht.image}`"
                      class="rounded d-block"
                      alt="..."
                      style="max-width: 200px"
                      @click="openImgModalWithReqToDb([favorite.yacht.id])"
                  />
                  <img
                      v-if="favorite.yacht.images"
                      :src="`${favorite.yacht.images[0]}`"
                      @click="openImgModal(favorite.yacht.images)"
                      class="rounded d-block"
                      alt="..."
                      style="max-width: 200px"
                  />
                </td>
                <td class="align-middle">
                  <router-link :to="'/yachts/' + favorite.yacht.id" class="router text-blue-600 text-1xl rounded-full">
                    {{ favorite.yacht.make }} {{ favorite.yacht.name }} | {{ formatPrice(favorite.yacht.price) }} {{ favorite.yacht.currency }}
                  </router-link>
                </td>
                <td class="align-middle">
                  <button
                      @click="removeFav(favorite)"
                      type="button"
                      class="btn btn-outline-danger"
                  >
                    Delete
                  </button>
                </td>
                <td v-if="getAuth">
                  <div class="flex items-center justify-center h-48">
                    <div class="flex items-center h-5">
                      <input id="helper-checkbox"
                             aria-describedby="helper-checkbox-text"
                             type="checkbox"
                             :value="favorite.yacht.id"
                             @change="toggleSelection(favorite)"
                             :checked="isSelected(favorite)"
                             class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <div style="text-align: right" class="mt-2" v-if="selected.length">
              <button @click="deleteAllSelected" title="Delete selected">
                <svg class="h-8 w-8 text-blue-400 hover:text-blue-500 hover:h-9 hover:w-9" width="24" height="24"
                     viewBox="0 0 24 24" stroke-width="2"
                     stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="4" y1="7" x2="20" y2="7" />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
              </button>
              <button title="Add to group" @click="openModalAddToGroup">
                <svg class="h-8 w-8 text-blue-400 hover:text-blue-500 hover:h-9 hover:w-9" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ImageModalComponent :images="imgModel"
                       :is-open="isImgModelOpen"
                       @modal-close="closeImgModal"/>
</template>

<style scoped>

</style>