<script setup>
    import {ref} from "vue";

    defineProps({
      title: {
        type: String,
        required: true
      }
    });

    let showMoreFilters = ref(false);

    const toggleFilters = () => {
      showMoreFilters.value = !showMoreFilters.value;
    }
</script>

<template>
  <div class="mb-4 text-center">
    <div>
      <button class="w-11/12 rounded-full border-2 rounded-t-lg bg-slate-700" v-if="!showMoreFilters" @click="toggleFilters">
        <h5 style="color: white" class="mt-2 mb-2"> {{ title }} &darr; </h5>
      </button>
      <button class="w-11/12 rounded-full border-2 rounded-t-lg bg-slate-300" v-if="showMoreFilters" @click="toggleFilters">
        <h5> {{ title }} &uarr; </h5>
      </button>
    </div>
    <div class="w-11/12 text-center m-auto bg-slate-100 mt-2 mb-4 rounded-xl shadow-xl shadow-grey-400" v-if="showMoreFilters">
      <slot name="content" />
    </div>
  </div>
</template>

<style scoped>

</style>