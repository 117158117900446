<template>
  <div class="filter-menu">
    <div class="filter-container mt-3">
      <form @submit.prevent="filterYachts">
        <div class="mb-3">
          <div class="ta-center">
            <h4>Type</h4>
          </div>
          <b-form-select id="type"
                         class="form-select"
                         :options="typeOptions"
                         v-model="filter.type" />
        </div>
        <div class="mb-3">
          <b-form-group>
            <div class="ta-center">
              <h4>Boat Condition</h4>
              <b-form-radio-group
                  v-model="filter.condition"
                  :options="conditionOptions"
                  buttons
                  class="custom-radio-group" />
            </div>
          </b-form-group>
        </div>


        <div class="mb-3">
          <div class="ta-center">
            <h4>Hull material</h4>
          </div>
          <b-form-select id="typeOfFuel"
                         class="form-select"
                         :options="hullMaterialOptions"
                         v-model="filter.hullMaterial" />
        </div>
        <div class="mb-3">
          <div class="ta-center">
            <h4>Location</h4>
          </div>
          <b-form-select id="typeOfFuel"
                         class="form-select"
                         :options="locationOptions"
                         v-model="filter.location" />
        </div>

        <div class="mb-3">
          <div class="ta-center">
            <h4>Name</h4>
          </div>
          <input placeholder="Name" type="text" id="make" class="form-control" v-model="filter.make">
        </div>
        <!--   LENGTH   -->
        <div class="mb-3">
          <div class="ta-center">
            <h4>Length</h4>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                  <input placeholder="Min Length" type="number" id="minLength" class="form-control"
                         v-model="filter.minLength">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                  <input placeholder="Max Length" type="number" id="maxLength" class="form-control"
                         v-model="filter.maxLength">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--   PRICE   -->
        <div class="mb-3">
          <div class="ta-center">
            <h4>Price</h4>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                  <input placeholder="Min Price" type="number" id="minPrice" class="form-control"
                         v-model="filter.minPrice">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                  <input placeholder="Max Price" type="number" id="maxPrice" class="form-control"
                         v-model="filter.maxPrice">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--   YEAR   -->
        <div class="mb-3">
          <div class="ta-center">
            <h4>Year</h4>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                  <input placeholder="Min Year" type="number" id="minYear" class="form-control"
                         v-model="filter.minYear">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                  <input placeholder="Max Year" type="number" id="maxYear" class="form-control"
                         v-model="filter.maxYear">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ta-center mb-3">
          <div id="app">
            <div class="mb-3">
              <button v-if="!showMoreFilters" style="border: 0; background: rgba(0, 0, 0, 0)" @click="toggleFilters">
                &darr; More filters &darr;
              </button>
              <button v-if="showMoreFilters" style="border: 0; background: rgba(0, 0, 0, 0)" @click="toggleFilters">
                &uarr; Hide more filters &uarr;
              </button>
            </div>
            <div v-if="showMoreFilters">
              <div class="mb-3">
                <div class="ta-center">
                  <h4>Type of fuel</h4>
                </div>
                <b-form-select id="typeOfFuel"
                               class="form-select"
                               :options="typeOfFuelOptions"
                               v-model="filter.typeOfFuel" />
              </div>
              <div class="mb-3">
                <div class="ta-center">
                  <h4>Number of cabin</h4>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-1"></div>
                      <div class="col-11">
                        <input placeholder="Min num" type="number" id="minNumCabin" class="form-control"
                               v-model="filter.minNumCabin">
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-1"></div>
                      <div class="col-11">
                        <input placeholder="Max num" type="number" id="maxNumCabin" class="form-control"
                               v-model="filter.maxNumCabin">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="ta-center">
                  <h4>Motor power</h4>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-1"></div>
                      <div class="col-11">
                        <input placeholder="Min power" type="number" id="minPower" class="form-control"
                               v-model="filter.minPower">
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-1"></div>
                      <div class="col-11">
                        <input placeholder="Max power" type="number" id="maxPower" class="form-control"
                               v-model="filter.maxPower">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ta-center mb-3">
          <button type="submit" class="btn btn-primary" @click="sendFilterToParent">
            Search
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import YachtService from "@/service/YachtService";

export default {
  data() {
    return {
      conditionOptions: [
        {text: 'New', value: 'new'},
        {text: 'Use', value: 'use'},
        {text: 'All', value: null}
      ],
      typeOptions: [
        {text: 'Motorboat', value: 'POWER'},
        {text: 'Sailboat', value: 'SAIL'},
        {text: 'All', value: null}
      ],
      typeOfFuelOptions: [
        {text: 'Petrol', value: 'PETROL'},
        {text: 'Diesel', value: 'DIESEL'},
        {text: 'Electric', value: 'ELECTRIC'},
        {text: 'Other', value: 'OTHER'},
        {text: 'All', value: null}
      ],
      hullMaterialOptions: [
        {text: 'Aluminum', value: 'ALUMINUM'},
        {text: 'Composite', value: 'COMPOSITE'},
        {text: 'FerroCement', value: 'FERROCEMENT'},
        {text: 'Fiberglass', value: 'FIBERGLASS'},
        {text: 'Hypalon', value: 'HYPALON'},
        {text: 'Pvc', value: 'PVC'},
        {text: 'Steel', value: 'STEEL'},
        {text: 'Wood', value: 'WOOD'},
        {text: 'Other', value: 'OTHER'},
        {text: 'All', value: null}
      ],
      locationOptions: [],
      filter: {
        type: null,
        condition: null,
        make: null,
        minLength: null,
        maxLength: null,
        minPrice: null,
        maxPrice: null,
        minYear: null,
        maxYear: null,
        typeOfFuel: null,
        hullMaterial: null,
        minPower: null,
        maxPower: null,
        minNumCabin: null,
        maxNumCabin: null,
        location: null
      },
      showMoreFilters: false
    };
  },
  methods: {
    sendFilterToParent() {
      this.$emit("send-filter", this.filter);
    },
    initWithFilters(filterProp) {
      for (const key in filterProp) {
        if (key in this.filter) {
          this.filter[key] = filterProp[key];
        }
      }
    },
    toggleFilters() {
      this.showMoreFilters = !this.showMoreFilters;
    },
    loadCountries() {
      YachtService.getAllCountries().then(response => {
        this.locationOptions = response.data.map(item => ({
          value: item.shortNameCountry, // Значение, которое будет выбрано
          text: `${item.nameCountry}` // Текст, который будет отображаться в списке
        }));
      })
          .catch(e => {
            console.log(e);
          });
      this.locationOptions.push({ value: null, text: 'All' });
    }
  },
  mounted() {
    this.loadCountries();
  }
};
</script>

<style scoped>
.filter-container {
  max-width: 400px;
  margin: 0 auto;
}

.filter-menu {
  background: #F8F9FA !important;
  border: 1px #CCCDCE solid;
  border-radius: 10px;
}

.ta-center {
  text-align: center !important;
}

.custom-radio-group .btn {
  width: 1700px; /* Задайте желаемую ширину кнопок */
}
</style>
