<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `Knotzone` }}</template>
    <template v-slot:meta="{ content }">{{ `${content}` }}</template>
  </metainfo>
  <div id="app">
    <WrapperMain>
      <div class="body-container">
        <!-- Ваш код компонентов здесь -->
        <MarineHeader />
        <div class="content-container">
          <!-- Код остальных компонентов и содержимого страницы -->
          <router-view />
        </div>
        <MarineFooter />
      </div>
    </WrapperMain>
    <CookieConsent />
  </div>
</template>

<script>

import MarineHeader from "@/components/Header.vue";
import MarineFooter from "@/components/Footer.vue";
import WrapperMain from "@/layouts/Wrapper.vue";
import CookieConsent from "@/components/cookie/CookieConsent.vue";
import {onMounted} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: 'App',
  components: {WrapperMain, MarineFooter, MarineHeader, CookieConsent},
  setup() {
    onMounted(async () => {
      try {
        useHead({
          title: 'Knotzone - Global Yacht Sales Listing Aggregator',
          meta: [
            {
              name: 'description',
              content: 'Knotzone is a global aggregator of yacht sales listings and a community for yachtsmen. Find all the information on yachts, purchasing, registration, and documentation in one place.',
            },
            {
              name: 'og:type',
              content: 'website',
            },
            {
              name: 'og:title',
              content: 'Knotzone - Global Yacht Sales Listing Aggregator',
            },
            {
              name: 'og:url',
              content: 'https://knotzone.com/',
            },
            {
              name: 'og:image',
              content: 'https://knotzone.com/our-logo.png',
            },
            {
              name: 'og:description',
              content: 'Knotzone is a global aggregator of yacht sales listings and a community for yachtsmen. Find all the information on yachts, purchasing, registration, and documentation in one place.',
            }
          ],
        });
      } catch (error) {
        console.error('meta error: ', error);
      }
    });
  }
}
</script>
<style>
.body-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  flex: 1;
  padding: 20px;
}
</style>
