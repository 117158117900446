<template>
  <div>
    <div class="accordion">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" @click="toggleBlock" :class="{ 'collapsed': !isActive }" :aria-expanded="isActive ? 'true' : 'false'" :aria-controls="collapseId">
            {{title}}
          </button>
        </h2>
        <div :id="collapseId" class="accordion-collapse collapse" :class="{ 'show': isActive }" aria-labelledby="headingOne" data-bs-parent=".accordion">
          <div class="accordion-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "marine-accordion",
  props: {
    title: {
      type: String,
      required: true
    },
    defaultShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isActive: this.defaultShow
    };
  },
  methods: {
    toggleBlock() {
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style>
/* Add any custom styles here */

</style>
