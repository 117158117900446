<template>
  <div v-if="!isConsentGiven" class="cookie-consent">
    We use cookies to improve your experience on our site. By clicking "Accept", you consent to the use of cookies.
    <a href="/privacy-policy" target="_blank">Learn more</a>
    <button @click="acceptCookies">Accept</button>
  </div>
</template>

<script>
/* global gtag */
export default {
  data() {
    return {
      isConsentGiven: false,
    };
  },
  methods: {
    acceptCookies() {
      this.isConsentGiven = true;
      document.cookie = "cookieConsent=true; max-age=" + 60 * 60 * 24 * 365 + "; path=/";

      // Активируем Google Analytics
      if (typeof gtag === 'function') {
        gtag('consent', 'update', {
          'analytics_storage': 'granted'
        });
      }
    },
    checkConsent() {
      this.isConsentGiven = document.cookie.split('; ').some(row => row.startsWith('cookieConsent='));
    },
  },
  mounted() {
    this.checkConsent();
  },
};
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2b2b2b;
  color: white;
  padding: 15px;
  text-align: center;
  z-index: 1000;
}
.cookie-consent button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}
.cookie-consent a {
  color: #4CAF50;
  text-decoration: underline;
  cursor: pointer;
}
</style>
