<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import store from "@/store";
import router from "@/router";
import ImageModalComponent from "@/components/ImageModalComponent.vue";

const favorites = ref([]);

const group = ref({name: ''});

const route = useRoute();

const isEditMode = ref(false);

const inputGroupName = ref('');

const inputVisible = ref(false);

const getUserId = computed(() => store.getters.getUserId);

const isImgModelOpen = ref(false);

const imgModel = ref([]);

/*METHODS*/

const closeImgModal = () => {
  isImgModelOpen.value = false;
};

const openImgModal = (img) => {
  imgModel.value = img
  isImgModelOpen.value = true;
};

const loadGroups = async () => {
  try {
    const id = route.params.id;
    const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/group/${id}`);
    group.value = response.data.group;
    favorites.value = response.data.group.favorite;
    inputGroupName.value = response.data.group.name;
    inputVisible.value = response.data.group.public;
  } catch (error) {
    console.error('Error loading groups:', error);
  }
};

const editGroup = () => {
  isEditMode.value = true;
};
const saveGroup = async () => {
  await axios.post(`https://api.knotzone.com/api/v1/favorite/update/group`, {
    groupName: inputGroupName.value,
    groupId: group.value.id,
    isPublic: inputVisible.value
  });
  await loadGroups();
  await router.push('/favorite');
  isEditMode.value = false;
};

const removeGroup = async () => {
  await axios.post(`https://api.knotzone.com/api/v1/favorite/remove/group`, {
    groupId: group.value.id,
    userId: getUserId.value
  });
  await router.push('/favorite');
};

const removeFavoriteFromGroup = async (favoriteId) => {

  const data = {
    groupId: group.value.id,
    favoriteId: favoriteId
  };

  await axios.post('https://api.knotzone.com/api/v1/favorite/remove/favorite/from/group', data)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });

  await loadGroups();
};

onMounted(() => {
  loadGroups();
});

</script>

<template>
  <div class="bg-white w-5/6 m-auto rounded-xl shadow-xl shadow-grey-400 mt-20">
    <h3 class="text-center">{{ group.name }}</h3>
    <div>
      <p v-if="!favorites" class="col-12 text-center mt-2">
        Group is empty
      </p>
      <div class="flex justify-end">
        <button v-if="!isEditMode" @click.prevent="editGroup" title="Edit"
                class="mr-2 place-items-end p-2.5 bg-blue-300 rounded-xl hover:rounded-3xl hover:bg-blue-400 transition-all duration-300 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>
        </button>
        <button v-if="isEditMode" @click.prevent="saveGroup" title="Save"
                class="mr-2 place-items-end p-2.5 bg-green-500 rounded-xl hover:rounded-3xl hover:bg-green-700 transition-all duration-300 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save size-6"
               viewBox="0 0 16 16">
            <path
                d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z" />
          </svg>
        </button>
        <router-link :to="'/favorite'">
          <button v-if="isEditMode" @click.prevent="removeGroup" title="Remove"
                  class="mr-2 place-items-end p-2.5 bg-red-300 rounded-xl hover:rounded-3xl hover:bg-red-400 transition-all duration-300 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>
          </button>
        </router-link>
      </div>
      <div v-if="isEditMode" class="text-center">
        <div class="ml-10 flex items-center space-x-4 w-auto mb-2">
          <label for="groupName" class=" block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Group name:
          </label>
          <input type="text"
                 id="groupName"
                 v-model="inputGroupName"
                 class="w-1/4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                 placeholder="Group name"
                 required />
        </div>
        <div class="ml-10 flex items-center space-x-4 w-auto">
          <label for="checked-checkbox"
                 class="text-sm font-medium text-gray-900 dark:text-gray-300">
            Other people can see your group:
          </label>
          <input checked
                 id="checked-checkbox"
                 type="checkbox"
                 v-model="inputVisible"
                 class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
        </div>
      </div>
      <div class="table-responsive mt-2">
        <table class="table col-12">
          <thead>
          <tr class="text-center">
            <th scope="col">#</th>
            <th scope="col">Image</th>
            <th scope="col">Name</th>
            <th v-if="isEditMode" scope="col">Delete</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(favorite, index) in favorites" :key="favorite.id">
            <th class="align-middle" scope="row">{{ index + 1 }}</th>
            <td class="align-middle">
              <img
                  :src="`${favorite.yacht.images[0]}` "
                  @click="openImgModal(favorite.yacht.images)"
                  class="rounded d-block mx-auto"
                  alt="yacht image"
                  style="width: 200px; height: 120px"
              />
            </td>
            <td class="align-middle text-center">
              <router-link :to="'/yachts/' + favorite.id" class="router text-blue-600 text-1xl rounded-full">
                {{ favorite.yacht.make }} {{ favorite.yacht.name }}
              </router-link>
            </td>
            <td class="align-middle" v-if="isEditMode">
              <button
                  @click="removeFavoriteFromGroup(favorite.id)"
                  type="button"
                  class="btn btn-outline-danger"
              >
                Delete
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ImageModalComponent :images="imgModel"
                       :is-open="isImgModelOpen"
                       @modal-close="closeImgModal"/>
</template>

<style scoped>

</style>