<script setup>

</script>

<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center px-4">
    <h1 class="text-6xl font-bold text-red-600">404</h1>
    <p class="text-xl text-gray-700 mt-3">Sorry, the page you requested was not found.</p>
    <router-link to="/" class="mt-6 text-white bg-blue-500 hover:bg-blue-700 font-medium py-2 px-4 rounded">
      Return to main page
    </router-link>
  </div>
</template>

<style scoped>

</style>