<template>
  <main class="form-signin w-100 m-auto">
    <img class="mb-4" src="../../assets/img/logo/our-logo.png" alt="our logo" width="290" height="200">
    <div v-if="notify.cls" :class="`alert alert-${notify.cls}`" role="alert">
      {{ notify.message }}
    </div>
    <form @submit.prevent="submit">
      <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
      <div class="form-floating">
        <input v-model="loginData.email" type="email" class="form-control" id="floatingInput"
               placeholder="name@example.com" required>
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input v-model="loginData.password" type="password" class="form-control" id="floatingPassword"
               placeholder="Password">
        <label for="floatingPassword">Password</label>
      </div>

      <div class="mb-3">
        <router-link to="/forgot">Forgot password?</router-link>
      </div>

      <button class="btn btn-primary w-100 py-2" type="submit">Sign in</button>
    </form>

  </main>
</template>

<script>
import {computed, defineComponent, reactive} from 'vue'
import {useRouter} from "vue-router";
import axios from "axios";
import store from "@/store";

export default defineComponent({
  name: "LoginForm",
  setup() {

    const notify = reactive({
      cls: '',
      message: 'Invalid email address or password'
    })

    const loginData = reactive({
      email: '',
      password: ''
    });

    const router = useRouter();

    const submit = async () => {
      try {
        const response = await axios.post('/auth/login', loginData, {
          withCredentials: true
        });

        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

        await router.push('/profile')
        await saveFavorite();
      } catch (e) {
        notify.cls = 'danger';
      }
    }

    const getFavorite = computed(() => store.getters.getFavorite);

    const getUserId = computed(() => store.getters.getUserId);

    const getAuth = computed(() => store.getters.getAuth);

    const saveFavorite = async () => {
      await axios.post('https://api.knotzone.com/api/v1/favorite/save/after/login', {
        userId: getUserId.value,
        favorites: getFavorite.value
      });

      if (getAuth.value) {
        try {
          const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/by/userId/${getUserId.value}`);
          const dbFav = response.data.favorites;
          await store.dispatch('loadFromDb', dbFav);
        } catch (error) {
          console.error('Error loading groups:', error);
        }
      }
    }

    return {
      loginData,
      submit,
      notify
    }
  },
})
</script>

<style scoped>
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>