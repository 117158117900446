import {createWebHistory, createRouter} from "vue-router";
import SearchForm from './components/SearchForm.vue';
import YachtForm from './components/Yacht.vue';
import LoginForm from "@/components/auth/LoginForm.vue";
import RegisterForm from "@/components/auth/RegisterForm.vue";
import Profile from "@/components/Profile.vue";
import Forgot from "@/components/auth/Forgot.vue";
import Reset from "@/components/auth/Reset.vue";
import axios from "axios";
import store from "@/store";
import Favorite from "@/view/Favorite.vue";
import GroupOfFavorite from "@/view/GroupOfFavorite.vue";
import Catalog from "@/view/Catalog.vue";
import CatalogYacht from "@/view/CatalogYacht.vue";
import PrivacyPolicy from "@/view/PrivacyPolicy.vue";
import NotFound from "@/view/NotFound.vue";

const pageRoute = [
    {
        path: '',
        name: 'first-page',
        component: SearchForm
    },
    {
        path: 'page/:page',
        name: 'paginationRoute',
        component: SearchForm,
        props: true
    }
]

const filterRoute = [
    {
        path: 'filters/:filters',
        name: 'filterRoute',
        component: SearchForm,
        props: true,
        children: [
            ...pageRoute
        ]
    }
]

const pageCatalogRoute = [
    {
        path: '',
        name: 'first-page',
        component: Catalog
    },
    {
        path: 'page/:page',
        name: 'paginationRoute',
        component: Catalog,
        props: true
    }
]

const filterCatalogRoute = [
    {
        path: 'q/:searchName',
        name: 'searchName',
        component: Catalog,
        props: true,
        children: [
            ...pageCatalogRoute
        ]
    }
]

const routes = [
    {
        path: "/",
        alias: "/yachts",
        name: "search-form",
        component: SearchForm,
        children: [
            {
                path: 'sort-by/:sort',
                name: 'sorting',
                component: SearchForm,
                props: true,
                children: [
                    ...filterRoute,
                    ...pageRoute
                ]
            },
            ...filterRoute,
            ...pageRoute
        ]
    },
    {
        path: "/yachts/:id",
        name: "Yacht-details",
        component: YachtForm
    },
    {
        path: "/login",
        name: "login-form",
        component: LoginForm
    },
    {
        path: "/register",
        name: "register-form",
        component: RegisterForm
    },
    {
        path: "/profile",
        name: "user-profile",
        component: Profile
    },
    {
        path: "/forgot",
        name: "forgot-password",
        component: Forgot
    },
    {
        path: "/reset/:token",
        name: "reset-password",
        component: Reset
    }
    ,
    {
        path: "/favorite",
        name: "Favorite",
        component: Favorite
    },
    {
        path: "/favorite/group/:id",
        name: "GroupOfFavorite",
        component: GroupOfFavorite
    },
    {
        path: "/catalog",
        name: "Catalog",
        alias: "/catalog",
        component: Catalog,
        children: [
            ...filterCatalogRoute,
            ...pageCatalogRoute
        ]
    },
    {
        path: "/catalog/yacht/:id",
        name: "yacht-from-catalog",
        component: CatalogYacht
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicy
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async () => {
    if (!store.state.auth) {
        await axios.get('auth/user')
            .then(response => {
                if (response.status !== undefined) {
                    if (response.data) {
                        store.dispatch('setUserId', response.data.id)
                    }
                    store.dispatch('setAuth', true);
                }
            })
    }
});

export default router;