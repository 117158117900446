<script setup>
  import ImageModalComponent from "@/components/ImageModalComponent.vue";
  import {ref} from "vue";

  defineProps({
    yachts: {
      type: Array,
      default: () => []
    }
  });

  const isImgModelOpen = ref(false);

  const imgModel = ref([]);

  const closeImgModal = () => {
    isImgModelOpen.value = false;
  };

  const openImgModal = (img) => {
    imgModel.value = img
    isImgModelOpen.value = true;
  };

  const formatPrice = (value) => {
    if (value == null) return 'price is not specified';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  
</script>

<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <p v-if="yachts.length === 0" class="col-12 text-center mt-2">
          This yacht is not for sale
        </p>
        <div v-else class="table-responsive mt-2">
          <table class="table col-12">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(yacht, index) in yachts" :key="yacht.id">
              <th class="align-middle" scope="row">{{ index + 1 }}</th>
              <td class="align-middle">
                <img
                    :src="`${yacht.images[0]}` "
                    @click="openImgModal(yacht.images)"
                    class="rounded d-block mx-auto"
                    alt="yacht image"
                    style="max-width: 200px"
                />
              </td>
              <td class="align-middle">
                <a :href="`https://knotzone.com/yachts/${yacht.id}`" target="_blank" style="text-decoration: none">
                  {{ yacht.name }}
                </a>
              </td>
              <td class="align-middle font-medium">
                {{formatPrice(yacht.price)}} {{yacht.currency}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ImageModalComponent :images="imgModel"
                       :is-open="isImgModelOpen"
                       @modal-close="closeImgModal"/>
</template>

<style scoped>

</style>