<template>
  <div v-if="currentYacht" class="edit-form">
    <form>
      <div class="row feature">
        <div class="col-md-2">
        </div>
        <div class="col-md-6">
          <marine-carousel :images=currentYacht.images>
          </marine-carousel>
          <div v-if="!isMobile">
            <div class="p-4 mx-auto bg-white rounded-lg shadow-md">
              <!-- Description -->
              <div v-if="currentYacht.description" class="mb-4">
                <p class="text-gray-700">{{ currentYacht.description }}</p>
              </div>

              <!-- Yacht Type and Hull Material -->
              <div v-if="currentYacht.yachtType || currentYacht.hullMaterial" class="mb-4">
              <span v-if="currentYacht.yachtType"
                    class="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Type:
                {{
                  currentYacht.yachtType
                }}</span>
                <span v-if="currentYacht.hullMaterial"
                      class="inline-block bg-gray-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">{{
                    currentYacht.hullMaterial
                  }}</span>
              </div>

              <!-- Yacht Dimensions -->
              <div v-if="currentYacht.yachtDimensions" class="mb-4">
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.nominalLengthFT">Nominal length:
                  {{ currentYacht.yachtDimensions.nominalLengthFT }} ft</p>
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.nominalLengthM">Nominal length:
                  {{ currentYacht.yachtDimensions.nominalLengthM }} m</p>
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.overallLengthFT">Overall length:
                  {{ currentYacht.yachtDimensions.overallLengthFT }} ft</p>
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.overallLengthM">Overall length:
                  {{ currentYacht.yachtDimensions.overallLengthM }} m</p>
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.waterlineLengthFT">Waterline length:
                  {{ currentYacht.yachtDimensions.waterlineLengthFT }} ft</p>
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.waterlineLengthM">Waterline length:
                  {{ currentYacht.yachtDimensions.waterlineLengthM }} m</p>
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.deckLengthFT">Deck length:
                  {{ currentYacht.yachtDimensions.deckLengthFT }} ft</p>
                <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.deckLengthM">Deck length:
                  {{ currentYacht.yachtDimensions.deckLengthM }} m</p>
              </div>

              <!-- Yacht Speed -->
              <div v-if="currentYacht.yachtSpeed" class="mb-4">
                <p class="text-sm text-gray-600">Max Speed: {{ currentYacht.yachtSpeed.max }} knots</p>
              </div>

              <!-- Location -->
              <div v-if="currentYacht.city || currentYacht.country || currentYacht.postalCode" class="mb-4">
                <p class="text-sm text-gray-600">
                  <span v-if="currentYacht.city">{{ currentYacht.city }}, </span>
                  <span v-if="currentYacht.postalCode"> ({{ currentYacht.postalCode }})</span>
                </p>
              </div>

              <!-- Engine Information -->
              <div v-if="currentYacht.engines && currentYacht.engines.length" class="mb-4">
                <h3 class="text-sm font-semibold text-gray-700">Engines:</h3>
                <ul class="list-disc list-inside text-gray-600">
                  <li v-for="(engine, index) in currentYacht.engines" :key="index">{{ engine.type }} - {{
                      engine.power
                    }} HP
                  </li>
                </ul>
              </div>

              <!-- Year of Manufacture -->
              <div v-if="currentYacht.year" class="mb-4">
                <p class="text-sm text-gray-600">Year: {{ currentYacht.year }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h1 v-if="currentYacht.id !== 36796" class="featurette-heading fw-normal lh-1" id="name"
              style="font-weight: bold !important;">
            {{ currentYacht.name }} | {{ getCondition(currentYacht.isNew) }}
          </h1>
          <h1 v-if="currentYacht.id === 36796" class="featurette-heading fw-normal lh-1" id="name"
              style="font-weight: bold !important;">
            Nova 27 | {{ getCondition(currentYacht.isNew) }}
          </h1>
          <h3 v-if="currentYacht.id !== 36796">
            <b>{{ formatPrice(currentYacht.price) }} {{ currentYacht.currency }} </b>
            {{ formatPriceEur(currentYacht.priceEUR) }}
          </h3>
          <div class="row" v-if="currentYacht.id === 36796">
            <div class="line-through font-bold">12.000 EUR</div>
            <div class="font-bold" style="color: rgba(43, 94, 25)">{{ formatPrice(currentYacht.price) }} {{
                currentYacht.currency
              }} {{ formatPriceEur(currentYacht.priceEUR) }}
            </div>
          </div>
          <div class="mt-3">
            <small v-if="currentYacht.createdDate != null" class="text-muted">
              Create date: {{ getDatePattern(currentYacht.createdDate) }}
            </small>
            <br>
            <div v-if="currentYacht.id !== 36796">
              <small v-if="currentYacht.modifiedDate != null" class="text-muted">
                Modified date: {{ getDatePattern(currentYacht.modifiedDate) }}
              </small>
              <br>
              <small v-if="currentYacht.lastUpdateDate != null" class="text-muted">
                Last check: {{ getTimeElapsed(currentYacht.lastUpdateDate) }}
              </small>
            </div>
            <div v-if="currentYacht.id === 36796">
              <b class="text-muted">
                The announcement is relevant!
              </b>
              <br>
              <b class="text-muted">
                A trusted seller!
              </b>
            </div>
          </div>
          <div @click="checkBeforeClickOnFavorite(currentYacht)" class="z-50 mt-2">
            <img class="shadow-lg shadow-cyan-800/70 rounded-lg"
                 :src="favorite(currentYacht) ? '/like-2.svg' : '/like-1.svg'" alt="Favorite"
                 style="width: 50px; height: 50px" />
          </div>
          <ModalComponent :is-open="isFavModalOpen"
                          @modal-close="closeModal"
                          @submit.prevent="clickFavorite(currentYacht)"
                          name="first-modal">
            <template #header>
              <div class="text-center w-full">
                <h3>Add group</h3>
              </div>
            </template>
            <template #content>
              <form @submit.prevent="clickFavorite(currentYacht)">
                <p>This yacht has been added to {{ numberOfFavorite }} groups of favorites, do you really want to remove
                  it
                  from favorites?</p>
                <div class="flex space-x-2">
                  <button
                      class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="submit">
                    Yes
                  </button>
                  <button
                      class="flex-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="button"
                      @click="closeModal">
                    Cansel
                  </button>
                </div>
              </form>
            </template>
            <template #footer>
            </template>
          </ModalComponent>

          <div v-if="currentYacht.yachtOwner" class="accordion mt-3">
            <div class="mb-1">
              <b-card>
                <b-card-body>
                  <b-card-text>
                    <p><b>Owner name: {{ currentYacht.yachtOwner.name }}</b></p>
                    <p style="border-top: black solid 1px"
                       v-if="currentYacht.id !== 36796 && currentYacht.yachtOwner.locale"><b>Locale:</b>
                      {{ currentYacht.yachtOwner.locale }}</p>
                    <p style="border-top: black solid 1px" v-if="currentYacht.id === 36796"><br><b>Locale:</b> <a
                        href="https://maps.app.goo.gl/f7tPZdeRqS2AxM5Q6" target="_blank">Vliho, Greece</a></p>
                    <div v-if="currentYacht.id !== 36796 && currentYacht.phoneNumbers.length">
                      <p style="border-top: black solid 1px"><b>Number</b></p>
                      <div v-for="(number, index) in currentYacht.phoneNumbers"
                           :key="index">
                        <li><i>{{ number.numberType }}:</i> {{ number.number }}</li>
                      </div>
                    </div>
                    <div v-if="currentYacht.id === 36796">
                      <b>Number</b> : +30 6949451404
                    </div>
                    <p v-if="currentYacht.nameCountry"><b>Country: {{ currentYacht.nameCountry }}</b></p>
                    <p v-if="currentYacht.year"><b>Year: {{ currentYacht.year }}</b></p>
                    <p v-if="currentYacht.yachtDimensions.nominalLengthFT"><b>Nominal length:
                      {{ currentYacht.yachtDimensions.nominalLengthFT }} foots</b></p>
                    <p v-if="currentYacht.yachtDimensions.nominalLengthM"><b>Nominal length M:
                      {{ currentYacht.yachtDimensions.nominalLengthM }} foots</b></p>
                    <p v-if="currentYacht.yachtDimensions.overallLengthFT"><b>Overall length FT:
                      {{ currentYacht.yachtDimensions.overallLengthFT }} meters</b></p>
                    <p v-if="currentYacht.yachtDimensions.overallLengthM"><b>Overall length M:
                      {{ currentYacht.yachtDimensions.overallLengthM }} meters</b></p>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <div class="mt-2" v-if="currentYacht.catalog">
            <router-link :to="`/catalog/yacht/${currentYacht.catalog.id}`">
              <button
                  class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
                Look at this yacht in the catalog
              </button>
            </router-link>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group  mt-3">
                <label for="postLink">More info: </label>
                <div v-for="(postUrl, eUrl) in currentYacht.eurlSites" :key="eUrl">
                  <a :href="postUrl" target="_blank" class="image-button" id="postLink">
                    <img :src="getLogo(eUrl)" :alt="eUrl" :title="eUrl" class="button-image">
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group  mt-3">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMobile" class="row">
        <div class="col-md-2"/>
        <div class="col-md-6">
          <div class="p-4 mx-auto bg-white rounded-lg shadow-md">
            <!-- Description -->
            <div v-if="currentYacht.description" class="mb-4">
              <p class="text-gray-700">{{ currentYacht.description }}</p>
            </div>

            <!-- Yacht Type and Hull Material -->
            <div v-if="currentYacht.yachtType || currentYacht.hullMaterial" class="mb-4">
                <span v-if="currentYacht.yachtType"
                      class="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Type:
                  {{
                    currentYacht.yachtType
                  }}</span>
              <span v-if="currentYacht.hullMaterial"
                    class="inline-block bg-gray-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">{{
                  currentYacht.hullMaterial
                }}</span>
            </div>

            <!-- Yacht Dimensions -->
            <div v-if="currentYacht.yachtDimensions" class="mb-4">
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.nominalLengthFT">Nominal length:
                {{ currentYacht.yachtDimensions.nominalLengthFT }} ft</p>
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.nominalLengthM">Nominal length:
                {{ currentYacht.yachtDimensions.nominalLengthM }} m</p>
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.overallLengthFT">Overall length:
                {{ currentYacht.yachtDimensions.overallLengthFT }} ft</p>
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.overallLengthM">Overall length:
                {{ currentYacht.yachtDimensions.overallLengthM }} m</p>
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.waterlineLengthFT">Waterline length:
                {{ currentYacht.yachtDimensions.waterlineLengthFT }} ft</p>
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.waterlineLengthM">Waterline length:
                {{ currentYacht.yachtDimensions.waterlineLengthM }} m</p>
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.deckLengthFT">Deck length:
                {{ currentYacht.yachtDimensions.deckLengthFT }} ft</p>
              <p class="text-sm text-gray-600" v-if="currentYacht.yachtDimensions.deckLengthM">Deck length:
                {{ currentYacht.yachtDimensions.deckLengthM }} m</p>
            </div>

            <!-- Yacht Speed -->
            <div v-if="currentYacht.yachtSpeed" class="mb-4">
              <p class="text-sm text-gray-600">Max Speed: {{ currentYacht.yachtSpeed.max }} knots</p>
            </div>

            <!-- Location -->
            <div v-if="currentYacht.city || currentYacht.country || currentYacht.postalCode" class="mb-4">
              <p class="text-sm text-gray-600">
                <span v-if="currentYacht.city">{{ currentYacht.city }}, </span>
                <span v-if="currentYacht.postalCode"> ({{ currentYacht.postalCode }})</span>
              </p>
            </div>

            <!-- Engine Information -->
            <div v-if="currentYacht.engines && currentYacht.engines.length" class="mb-4">
              <h3 class="text-sm font-semibold text-gray-700">Engines:</h3>
              <ul class="list-disc list-inside text-gray-600">
                <li v-for="(engine, index) in currentYacht.engines" :key="index">{{ engine.type }} - {{
                    engine.power
                  }} HP
                </li>
              </ul>
            </div>

            <!-- Year of Manufacture -->
            <div v-if="currentYacht.year" class="mb-4">
              <p class="text-sm text-gray-600">Year: {{ currentYacht.year }}</p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import YachtService from "../service/YachtService";
import {computed, onMounted, ref, watch} from "vue";
import store from "@/store";
import axios from "axios";
import {mapGetters, mapMutations} from "vuex";
import ModalComponent from "@/components/UI/ModalComponent.vue";
import {useHead} from "@vueuse/head";
import {useRoute} from "vue-router";

export default {
  name: "marine-yacht",
  components: {ModalComponent},
  data() {
    return {
      message: '',
      items: [],
      isLoading: false,
      description: undefined,
      isFavModalOpen: false,
      numberOfFavorite: 0,
      isMobile: false
    };
  },
  created() {
    this.checkIfMobile(); // Проверяем ширину экрана при загрузке компонента
    window.addEventListener('resize', this.checkIfMobile); // Добавляем слушатель события изменения размера окна
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile); // Убираем слушатель события при уничтожении компонента
  },
  setup() {
    const route = useRoute();
    const currentYacht = ref(null);
    const id = route.params.id;
    onMounted(async () => {
      try {
        await YachtService.get(id)
            .then(response => {
              currentYacht.value = response.data;
            })
            .catch(e => {
              console.log(e);
            });
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    });

    // Следим за изменением currentYacht и обновляем мета-теги
    watch(currentYacht, (newValue) => {
      if (newValue) {
        useHead({
          title: `KnotZone - buy ${newValue.name}`,
          meta: [
            {
              name: 'description',
              content: `For sale: ${newValue.name}, priced at ${newValue.price}. Perfect yacht for coastal cruising.`,
            },
            {
              name: 'og:type',
              content: 'website',
            },
            {
              name: 'og:title',
              content: `KnotZone - buy ${newValue.name}`,
            },
            {
              name: 'og:url',
              content: `https://knotzone.com/yachts/${newValue.id}`,
            },
            {
              name: 'og:image',
              content: getImgOGImage(newValue), // Убедитесь, что эта функция доступна
            },
            {
              name: 'og:description',
              content: `For sale: ${newValue.name}, priced at ${newValue.price}. Perfect yacht for coastal cruising.`,
            },
          ],
        });
      }
    });

    function getImgOGImage(yacht) {
      if (yacht.images.length) {
        return yacht.images[0];
      }
      return null;
    }

    return {
      currentYacht,
      getImgOGImage
    };
  },
  methods: {
    getDescription() {
      if (this.currentYacht != null)
        return this.currentYacht.name
      if (!this.description) {
        // if GET request failed or returned empty,
        // explicitly set to undefined so the parents'
        // default description is used
        return undefined
      }
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // Проверяем, меньше ли ширина экрана 768px
    },
    closeModal() {
      this.isFavModalOpen = false;
    },
    async checkBeforeClickOnFavorite(yacht) {
      const getAuth = computed(() => store.getters.getAuth);
      const getUserId = computed(() => store.getters.getUserId);
      if (getAuth.value && this.favorite(yacht)) {
        const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/exist/yacht/${yacht.id}/in/${getUserId.value}`);
        if (response.data.number === 0) {
          this.clickFavorite(yacht);
        } else {
          this.numberOfFavorite = response.data.number;
          this.isFavModalOpen = true;
        }
      } else {
        this.clickFavorite(yacht);
      }
    },
    ...mapMutations(["editFavorite"]),
    clickFavorite(yacht) {
      this.editFavorite(yacht);
      this.isFavModalOpen = false;
    },
    ...mapGetters(["getFavorite"]),
    favorite(yacht) {
      let isFav = false;
      if (this.getFavorite()) {
        this.getFavorite().forEach(fav => {
          if (fav.yacht != null && fav.yacht.id === yacht.id) {
            isFav = true;
          }
        });
      }
      return isFav;
    },
    getDatePattern(date) {
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
      const day = String(selectedDate.getDate()).padStart(2, '0');
      return `${year}.${month}.${day}`;
    },
    getImage(imageName) {
      return require(`@/../public/dbImg/${imageName}`);
    },
    formatPrice(value) {
      if (value == null) return 'price is not specified';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPriceEur(value) {
      if (value == null) return '';
      if (this.currentYacht.currency === 'EUR') return '';
      return '( ≈ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' EUR)';
    },
    getCondition(isNew) {
      if (isNew) {
        return 'NEW';
      } else {
        return 'USED';
      }
    },
    // calculate update time
    getTimeElapsed(date) {
      const currentTime = new Date();
      const timeDiffInSeconds = Math.floor((currentTime - new Date(date)) / 1000);

      if (timeDiffInSeconds < 60) {
        return 'Less than a minute ago';
      } else if (timeDiffInSeconds < 3600) {
        const minutes = Math.floor(timeDiffInSeconds / 60);
        return `${minutes} minutes ago`;
      } else if (timeDiffInSeconds < 3600 * 24) {
        const hours = Math.floor(timeDiffInSeconds / 3600);
        const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
        return `${hours} hours and ${minutes} minutes ago`;
      } else {
        const days = Math.floor(timeDiffInSeconds / (3600 * 24));
        if (days > 1) {
          return `${days} days ago`
        } else {
          return `${days} day ago`
        }
      }
    },
    updateYacht(id) {
      this.isLoading = true;
      YachtService.update(id)
          .then(response => {
            this.currentYacht = response.data;
          })
          .catch(e => {
            console.log(e);
          }).finally(() => this.isLoading = false)
    },
    getLogo(eUrl) {
      switch (eUrl) {
        case "BOTENTEKOOP":
          return require(`@/assets/img/logo/botentekoop-logo.svg`);
        case "BOATSANDOUTBOARDS":
          return require(`@/assets/img/logo/boatsandoutboards-logo.svg`);
        case "ANNONCESBATEAU":
          return require(`@/assets/img/logo/annoncesbateau-logo.svg`);
        case "BOOT24":
          return require(`@/assets/img/logo/boot24-logo.svg`);
        case "FINN":
          return require(`@/assets/img/logo/finn-logo.png`);
        case "BLOCKET":
          return require(`@/assets/img/logo/blocket-logo.jpg`);
        case "CARGR":
          return require(`@/assets/img/logo/cargr-logo.png`);
        case "YACHTALL":
          return require(`@/assets/img/logo/yachtall-logo.jpg`);
        default:
          return '';
      }
    }
  },
  async mounted() {
    this.message = '';
  },
  computed() {
  }
};
</script>

<style>
.edit-form {
  margin: auto;
}

.image-button {
  display: inline-block;
  width: 200px; /* Задайте нужную ширину кнопки */
  height: 50px; /* Задайте нужную высоту кнопки */
}

.button-image {
  margin-left: 10%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button-image:hover {
  margin-left: 10%;
  width: 105%;
  height: 105%;
  object-fit: contain;
}

.div-row {
  margin-top: 20px;
}

/* Стили для кнопки и спиннера */
.custom-button {
  position: relative;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
