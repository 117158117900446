<script setup>

import {ref} from "vue";
import ImageModalComponent from "@/components/ImageModalComponent.vue";

defineProps({
  favorites: {
    type: Array,
    default: () => []
  },
  groupId: {
    type: Number,
    default: null,
    require: true
  }
  });

const isImgModelOpen = ref(false);

const imgModel = ref([]);

const closeImgModal = () => {
  isImgModelOpen.value = false;
};

const openImgModal = (img) => {
  imgModel.value = img
  isImgModelOpen.value = true;
};

</script>

<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <p v-if="favorites.length === 0" class="col-12 text-center mt-2">
          Group is empty
        </p>
        <div v-else class="table-responsive mt-2">
          <table class="table col-12">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(favorite, index) in favorites" :key="favorite.id">
              <th class="align-middle" scope="row">{{ index + 1 }}</th>
              <td class="align-middle">
                <img
                    :src="`${favorite.yacht.images[0]}` "
                    @click="openImgModal(favorite.yacht.images)"
                    class="rounded d-block mx-auto"
                    alt="yacht image"
                    style="max-width: 200px"
                />
              </td>
              <td class="align-middle">
                <router-link :to="'/yachts/' + favorite.id" class="router text-blue-600 text-1xl rounded-full">
                  {{ favorite.yacht.make }} {{ favorite.yacht.name }}
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center mt-4 mb-2 text-2xl">
          <router-link class="router text-blue-600 border-b" :to="'/favorite/group/' + groupId">Click for edit group</router-link>
        </div>
      </div>
    </div>
  </div>
  <ImageModalComponent :images="imgModel"
                       :is-open="isImgModelOpen"
                       @modal-close="closeImgModal"/>
</template>

<style scoped>

</style>