import http from "../http-common";

class YachtService {

    getCountOfPage(filter) {
        const queryFiltersParams = new URLSearchParams(filter).toString();
        return http.get(`/yachts/page/count/${queryFiltersParams}`);
    }

    getAllByPageNumber(sortType, filter, page) {
        const queryFiltersParams = new URLSearchParams(filter).toString();
        return http.get(`/yachts/${sortType}/${queryFiltersParams}/${page}`);
    }

    get(id) {
        return http.get(`/yacht/${id}`);
    }

    getPrev(id) {
        return http.get(`/yacht/prev/${id}`);
    }

    getAllCountries() {
        return http.get('/yachts/all/countries');
    }

    update(id) {
        return http.get(`/yachts/update/yacht/by/id/${id}`);
    }

    saveFavoriteGroup(data) {
        return http.post(`/yachts/favorite/group/save`, data)
    }

}

export default new YachtService();
