<script>
import {computed, defineComponent} from 'vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import store from "@/store";
import axios from "axios";

export default defineComponent({
  name: "WrapperMain",
  computed: {
    ...mapGetters(["getFavorite"], ["getAuth"])
  },
  methods: {
    ...mapMutations(["editFavorite"]),
    ...mapActions(["getLocalData"]),
    async intiFav() {
      const getAuth = computed(() => store.getters.getAuth);
      const getUserId = computed(() => store.getters.getUserId);
      if (getAuth.value) {
        try {
          const response = await axios.get(`https://api.knotzone.com/api/v1/favorite/by/userId/${getUserId.value}`);
          const dbFav = response.data.favorites;
          await store.dispatch('loadFromDb', dbFav);
        } catch (error) {
          console.error('Error loading groups:', error);
        }
      }
    }
  },
  mounted() {
    this.getLocalData();
    this.intiFav();
  }
})
</script>

<template>
  <main>
    <slot />
  </main>
</template>