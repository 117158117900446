<template>
  <main class="form-signin w-100 m-auto">
    <img class="mb-4" src="../../assets/img/logo/our-logo.png" alt="our logo" width="290" height="200">
    <form @submit.prevent="submit">
      <div v-if="notify.cls" :class="`alert alert-${notify.cls}`" role="alert">
        {{ notify.message }}
      </div>
      <h1 class="h3 mb-3 fw-normal" style="text-align: center">Please register</h1>
      <div class="form-floating">
        <input v-model="registerData.first_name" class="form-control mb-3" placeholder="First Name">
        <label>First name</label>
      </div>
      <div class="form-floating">
        <input v-model="registerData.last_name" class="form-control mb-3" placeholder="Last Name">
        <label>Last name</label>
      </div>
      <div class="form-floating">
        <input v-model="registerData.email" type="email" class="form-control mb-3" placeholder="name@example.com">
        <label>Email address</label>
      </div>
      <div class="form-floating">
        <input v-model="registerData.password" type="password" class="form-control mb-3" placeholder="Password">
        <label>Password</label>
      </div>
      <div class="form-floating">
        <input v-model="registerData.password_confirm" type="password" class="form-control mb-3"
               placeholder="Password Confirm">
        <label>Password Confirm</label>
      </div>
      <button class="btn btn-primary w-100 py-2" type="submit">Submit</button>
    </form>
  </main>
</template>

<script>
import {defineComponent, reactive} from 'vue'
import {useRouter} from "vue-router";
import axios from "axios";

export default defineComponent({
  name: "RegisterForm",
  setup() {
    const notify = reactive({
      cls: '',
      message: 'Passwords do not match'
    })

    const registerData = reactive({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirm: '',
    });

    const router = useRouter();

    const submit = async () => {
      await axios.post(`/auth/register`, registerData)
        .then(resp => {
          if (resp.status === 200) {
            router.push('/login');
          } else {
            notify.cls = 'danger';
          }
      });
    }

    return {
      registerData,
      submit,
      notify
    }
  },
})
</script>

<style scoped>
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>