import {createStore} from 'vuex'
import axios from "axios";

export default createStore({
    state: () => ({
        auth: false,
        message: '',
        userId: null,
        user: {
            favorite: []
        }
    }),
    getters: {
        getFavorite(state) {
            return state.user.favorite;
        },
        getAuth(state) {
            return state.auth;
        },
        getUserId(state) {
            return state.userId;
        }
    },
    mutations: {
        setAuth: (state, auth) => {
            state.auth = auth;
            localStorage.setItem("auth", JSON.stringify(state.auth))
        },
        setUserId: (state, userId) => {
            state.userId = userId;
            localStorage.setItem("userId", JSON.stringify(state.userId))
        },
        setMessage: (state, message) => {
            state.message = message;
        },
        async editFavorite(state, data) {
            let hasFavorite = false;
            for (const item of state.user.favorite) {
                const index = state.user.favorite.indexOf(item);
                if ((data.yacht && item.yacht.id === data.yacht.id) || (item.yacht.id === data.id)) {
                    state.user.favorite.splice(index, 1);
                    if (state.auth) {
                        try {
                            await axios.post('https://api.knotzone.com/api/v1/favorite/remove', {
                                favorite: data,
                                userId: state.userId
                            });
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    hasFavorite = true;
                }
            }
            if (!hasFavorite) {
                const favData = {
                    isGrouped: false,
                    yacht: data
                }
                if (state.auth) {
                    try {
                        axios.post('https://api.knotzone.com/api/v1/favorite/save/to/user', {
                            yachtId: data.id,
                            userId: state.userId
                        }).then(r => console.log(r));
                    } catch (e) {
                        console.log(e)
                    }
                }
                state.user.favorite.push(favData);
            }
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        loadFromDbIFAuth(state, data) {
            state.user.favorite.splice(0, state.user.favorite.length);
            if (data != null) {
                data.forEach(item => {
                    if (item.yacht) {
                        state.user.favorite.push({isGrouped: item.grouped, yacht: item.yacht});
                    }
                });
            }
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        writeUserData(state, data) {
            state.user = data;
        },
        writeAuth(state, data) {
            state.auth = data;
        },
        writeUserId(state, data) {
            state.userId = data;
        },
        clearData(state) {
            state.user.favorite.splice(0, state.user.favorite.length);
            localStorage.setItem("user", JSON.stringify(state.user));
            state.userId = null;

        }
    },
    actions: {
        setAuth: (context, auth) => {
            context.commit('setAuth', auth);
        },
        setUserId: (context, userId) => {
            context.commit('setUserId', userId);
        },
        setMessage: (context, message) => {
            context.commit('setMessage', message);
        },
        loadFromDb: (context, message) => {
            context.commit('loadFromDbIFAuth', message)
        },
        getLocalData({commit}) {
            // user
            let user = null;
            if (localStorage.getItem("user")) {
                user = localStorage.getItem("user");
                commit("writeUserData", JSON.parse(user));
            }
            // auth
            let auth = null;
            if (localStorage.getItem("auth")) {
                auth = localStorage.getItem("auth");
                commit("writeAuth", JSON.parse(auth));
            }
            // userId
            let userId = null;
            if (localStorage.getItem("userId")) {
                userId = localStorage.getItem("userId");
                commit("writeUserId", JSON.parse(userId));
            }
        },
        clearLocalDate({commit}) {
            commit("clearData");
        }
    },
    modules: {}
});
